import React, {useEffect} from 'react';
import {Box, Button, Card, CardActionArea, CardActions, CardMedia} from "@mui/material";
import CM from "./images/CM.jpg"
import BA from "./images/BA.png"
import SC from "./images/SC.jpg"
import RT from "./images/RT.png"
import {useNavigate} from "react-router-dom";
import Navbar from "../elements/Navbar";
import {SELECTED_GAME} from "../constants";


export const GameSelector = () => {
    const navigate = useNavigate()
    function handleSelectGame(game) {
        if (game !== SELECTED_GAME.NO_GAME) {
            if (game === SELECTED_GAME.NO_GAME) {
                navigate(`/select-game`)
            } else {
                // window.localStorage.setItem("game", game)
                navigate(`/${game.toLowerCase()}`)
            }
        }

    }

    return <>
        <Navbar handleSelectGame={handleSelectGame}/>
        <Box sx={{display: "flex", justifyContent: "center", height: "100%", marginTop: "140px"}}>
            <Card sx={{width: 350, height: 300, mx: "10px"}}>
                <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.CM)}>
                    <CardMedia
                        component="img"
                        height="250"
                        image={CM}
                        alt="Clockmaker"
                    />
                </CardActionArea>
                <CardActions sx={{justifyContent: "center"}}>
                    <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.CM)}>
                        CLOCKMAKER CALENDAR
                    </Button>
                </CardActions>
            </Card>
            <Card sx={{width: 350, height: 300, mx: "10px"}}>
                <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.SC)}>
                    <CardMedia
                        component="img"
                        height="250"
                        image={SC}
                        alt="Solitaire Cruise"
                    />
                </CardActionArea>
                <CardActions sx={{justifyContent: "center"}}>
                    <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.SC)}>
                        SOLITAIRE CRUISE Automatic calendar
                    </Button>
                </CardActions>
            </Card>
            <Card sx={{width: 350, height: 300, mx: "10px"}}>
                <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.BA)}>
                    <CardMedia
                        component="img"
                        height="250"
                        image={BA}
                        alt="Bermuda Adventure"
                    />
                </CardActionArea>
                <CardActions sx={{justifyContent: "center"}}>
                    <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.BA)}>
                        BERMUDA Automatic calendar
                    </Button>
                </CardActions>
            </Card>
            <Card sx={{width: 350, height: 300, mx: "10px"}}>
                <CardActionArea onClick={() => handleSelectGame(SELECTED_GAME.RT)}>
                    <CardMedia
                        component="img"
                        height="250"
                        image={RT}
                        alt="Roger That"
                    />
                </CardActionArea>
                <CardActions sx={{justifyContent: "center"}}>
                    <Button size="small" color="primary" onClick={() => handleSelectGame(SELECTED_GAME.RT)}>
                        ROGER THAT CALENDAR
                    </Button>
                </CardActions>
            </Card>
        </Box>
    </>
}